(function( $ ) {

    $.fn.dropDownMenu = function() {

        var jqAllAffectedContainers = this;
 
        var updateHeight = function() {
            var allSubMenues = jqAllAffectedContainers.find('.has-sub-menu');
            allSubMenues.each(function() {
                var jqContainer = $(this);
                var subMenuContainer = jqContainer.find('>.sub-menu').first();
                var subMenuInnerContainer = subMenuContainer.find('>.sub-menu-holder').first();
                var openHeight = subMenuInnerContainer.innerHeight()
                var closeHeight = 0;
                if (jqContainer.hasClass('open')) {
                    subMenuContainer.height(openHeight);
                } else {
                    subMenuContainer.height(closeHeight);
                }
            });

        }

        var setEvent = function(branches, level) {
            $(branches).each(function() {
                var branch = this;
                var jqContainer = branch.parent;
                setBaseEvent(jqContainer, branches);
                if (branch.children) {
                    setEvent(branch.children, level + 1);
                }
            });

        }

        var closeBranches = function(branches) {
            $(branches).each(function() {
                var jqContainer = this.parent;
                jqContainer.removeClass('open');
            })
            updateHeight();
        }

        var setBaseEvent = function(jqContainer, subTree) {
            var menuOpenerLink = jqContainer.find('>a').first();

            //events
            menuOpenerLink.click(function(e) {
                if (jqContainer.hasClass('open')) {

                } else {
                    e.preventDefault();
                    closeBranches(subTree);
                    jqContainer.addClass('open');
                    var currentSubMenuContainer = jqContainer.find('>.sub-menu').first();
                    var currentSubMenuInnerContainer = currentSubMenuContainer.find('>.sub-menu-holder').first();
                    var currentOpenHeight = currentSubMenuInnerContainer.innerHeight()
                    currentSubMenuContainer.height(currentOpenHeight);
                    setTimeout(updateHeight, 350);
                }
            });
        }

        var buildTree = function(subTree, compliteTree) {
            var topLevelContainers = subTree.find('>.has-sub-menu');
            topLevelContainers.each(function() {
                var topLevelContainer =  $(this);
                var nextSubLevel = topLevelContainer.find('>.sub-menu ul>.has-sub-menu')
                var branch = {
                    parent: topLevelContainer,
                    children: null
                }
                if (nextSubLevel.length > 0) {
                    var nextSubTree = topLevelContainer.find('>.sub-menu ul');
                    branch.children = buildTree(nextSubTree, [])
                }
                compliteTree.push(branch);
            });
            return compliteTree;
        }

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var tree = null;
            tree = buildTree(jqContainer, []);
            setEvent(tree, 1);
            updateHeight();
            setTimeout(updateHeight, 50);
        });
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.catalog-menu-dropdown .main-level').dropDownMenu();
});
